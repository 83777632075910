import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignsPost, faBookOpen, faBriefcase, faBrowser, faBuildingColumns, faBullseyeArrow, faChalkboardUser, faCircleDollar, faCrown, faEllipsisStroke, faFlagSwallowtail, faGrid2Plus, faSearch, faTrophy, faUserGroup, faPen, faUpload, faTableTree, faFolderOpen, faTerminal } from '@fortawesome/pro-regular-svg-icons';
import { ItemProps } from '@tryhackme/thm-ui-components/dist/components/atoms/desktop-nav/desktop-nav.types';
import { useGetFeatureFlagsQuery, useGetUserQuery } from 'src/common/slices';
import { extractB2BUserRoles } from 'src/common/helpers';
import { FeatureFlagName } from 'src/common/constants';
import { UserRoles } from 'src/common/enums';
import { useHostRoadmapsAsSeparatePageExperiment } from '../hacktivities/roadmap/roadmap.helpers';
const conditionalLink = <T,>(condition: boolean, link: T) => condition ? [link] : [];
export const useMainNavigationLinks = (): ItemProps[] => {
  const {
    data: featureFlagsQueryData
  } = useGetFeatureFlagsQuery();
  const {
    data: getUserQueryData
  } = useGetUserQuery();
  const {
    isHostRoadmapsAsSeparatePageExperimentEnabled
  } = useHostRoadmapsAsSeparatePageExperiment();
  return useMemo(() => {
    const enabledFeatureFlags = featureFlagsQueryData?.data?.filter(flag => flag.enabled).map(flag => flag.name) ?? [];
    const isNewPracticePageEnabled = enabledFeatureFlags.includes(FeatureFlagName.REACT_NEW_PRACTICE_PAGE);
    const userData = getUserQueryData?.data?.user;
    const userB2BUserRoles = extractB2BUserRoles(userData);
    const isRoomDeveloper = userData?.roles?.includes(UserRoles.ROOM_MAKER);
    const Learn = ({
      name: 'Learn',
      icon: <FontAwesomeIcon icon={faBookOpen} />,
      visibleIn: 'both',
      subItems: [...conditionalLink(isHostRoadmapsAsSeparatePageExperimentEnabled, ({
        name: 'Roadmap',
        icon: <FontAwesomeIcon icon={faSignsPost} />,
        description: 'Your career guide',
        href: '/hacktivities',
        visibleIn: 'both'
      } as const)), {
        name: 'Learn',
        icon: <FontAwesomeIcon icon={faBookOpen} />,
        description: 'Hands-on Hacking',
        href: isHostRoadmapsAsSeparatePageExperimentEnabled ? '/hacktivities/learn' : '/hacktivities',
        visibleIn: 'both'
      }, {
        name: isNewPracticePageEnabled ? 'Challenges' : 'Practice',
        icon: <FontAwesomeIcon icon={faBullseyeArrow} />,
        description: 'Reinforce your learning',
        href: `/hacktivities/${isNewPracticePageEnabled ? 'challenges' : 'practice'}`,
        visibleIn: 'both'
      }, {
        name: 'Search',
        icon: <FontAwesomeIcon icon={faSearch} />,
        description: 'Explore over 900 rooms',
        href: '/hacktivities/search',
        visibleIn: 'both'
      }, ...conditionalLink(enabledFeatureFlags.includes(FeatureFlagName.REACT_USER_ASSIGNMENTS_PAGE) && userB2BUserRoles.isB2BUserActive, ({
        name: 'Assignments',
        icon: <FontAwesomeIcon icon={faPen} />,
        description: 'Learn, practice, progress',
        href: '/assignments',
        visibleIn: 'both'
      } as const))]
    } satisfies ItemProps);
    return [{
      name: 'Dashboard',
      icon: <FontAwesomeIcon icon={faBrowser} />,
      href: '/dashboard',
      visibleIn: 'private',
      visibleInSplitScreen: true
    }, {
      ...Learn,
      visibleIn: 'public',
      visibleInSplitScreen: true
    }, {
      ...Learn,
      visibleIn: 'private'
    }, {
      name: 'Compete',
      icon: <FontAwesomeIcon icon={faTrophy} />,
      visibleIn: 'public',
      visibleInSplitScreen: true,
      subItems: [{
        name: 'King of the Hill',
        icon: <FontAwesomeIcon icon={faCrown} />,
        description: 'Attack & Defend',
        href: '/games/koth',
        visibleIn: 'public'
      }, {
        name: 'Leaderboards',
        icon: <FontAwesomeIcon icon={faTrophy} />,
        description: 'Platform Rankings',
        href: '/leaderboards',
        visibleIn: 'public'
      }]
    }, {
      name: 'Compete',
      icon: <FontAwesomeIcon icon={faTrophy} />,
      visibleIn: 'private',
      subItems: [{
        name: 'Leaderboards',
        icon: <FontAwesomeIcon icon={faTrophy} />,
        description: 'Platform Rankings',
        href: '/leaderboards',
        visibleIn: 'private'
      }, {
        name: 'King of the Hill',
        icon: <FontAwesomeIcon icon={faCrown} />,
        description: 'Attack & Defend',
        href: '/games/koth',
        visibleIn: 'private'
      }, {
        name: 'Workspace',
        icon: <FontAwesomeIcon icon={faUserGroup} />,
        description: 'Compete & Collaborate',
        href: '/workspace',
        visibleIn: 'private'
      }, ...conditionalLink(enabledFeatureFlags.includes(FeatureFlagName.REACT_CTF_BUILDER_PAGE) && (userB2BUserRoles.isB2BUserActive || userB2BUserRoles.isCompanyAdmin), ({
        name: 'CTF Builder',
        icon: <FontAwesomeIcon icon={faFlagSwallowtail} />,
        href: '/ctf-builder',
        description: 'Create your own CTF event',
        visibleIn: 'private'
      } as const))]
    }, ...conditionalLink(Boolean(isRoomDeveloper), ({
      name: 'Develop',
      icon: <FontAwesomeIcon icon={faTerminal} />,
      subItems: [{
        name: 'Upload',
        icon: <FontAwesomeIcon icon={faUpload} />,
        description: 'Upload VMs & Files',
        href: '/upload',
        visibleIn: 'private'
      }, {
        name: 'Manage Rooms',
        icon: <FontAwesomeIcon icon={faTableTree} />,
        description: 'Create and Manage Rooms',
        href: '/manage-rooms',
        visibleIn: 'private'
      }, {
        name: 'Your Material',
        icon: <FontAwesomeIcon icon={faFolderOpen} />,
        description: 'View your uploads',
        href: '/your-material',
        visibleIn: 'private'
      }],
      visibleIn: 'private'
    } as ItemProps)), {
      name: 'For Education',
      icon: <FontAwesomeIcon icon={faBuildingColumns} />,
      subItems: [{
        name: 'Teaching',
        icon: <FontAwesomeIcon icon={faChalkboardUser} />,
        description: 'Use our security labs',
        href: '/teaching',
        visibleIn: 'public'
      }, {
        name: 'Create labs',
        icon: <FontAwesomeIcon icon={faGrid2Plus} />,
        description: 'Upload & deploy VMs',
        href: '/develop-rooms',
        visibleIn: 'public'
      }],
      visibleIn: 'public'
    }, {
      name: 'For Business',
      icon: <FontAwesomeIcon icon={faBriefcase} />,
      href: 'https://business.tryhackme.com',
      visibleIn: 'public',
      visibleInSplitScreen: true
    }, {
      name: 'Pricing',
      icon: <FontAwesomeIcon icon={faCircleDollar} />,
      href: '/pricing',
      visibleIn: 'public'
    }, {
      name: 'Other',
      icon: <FontAwesomeIcon icon={faEllipsisStroke} />,
      subItems: [...conditionalLink(userB2BUserRoles.isCompanyAdmin, {
        name: 'Management Dashboard',
        href: `/client/${userData?.companies?.[0]?._id ?? ''}`
      }), {
        name: 'For Business',
        href: 'https://business.tryhackme.com'
      }, {
        name: 'For Education',
        href: '/classrooms'
      }, {
        name: 'Career Hub',
        href: '/careers'
      }, {
        name: 'Resources',
        href: '/resources/blog'
      }, {
        name: 'Glossary',
        href: '/glossary'
      }, {
        name: 'Buy Vouchers',
        href: '/subscriptions'
      }, {
        name: 'Develop Rooms',
        href: '/develop-rooms'
      }, {
        name: 'Swag Shop',
        href: '/swag-shop'
      }],
      visibleIn: 'private',
      visibleInSplitScreen: true
    }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlagsQueryData, getUserQueryData?.data?.user, isHostRoadmapsAsSeparatePageExperimentEnabled]);
};