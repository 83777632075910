import { StatusEnum } from '@tryhackme/thm-ui-components';

import {
  UserAccountTypes,
  UserExperienceCyber,
  UserExperienceCyberDetail,
  UserField,
  UserGoal,
  UserHowHeard,
  UserRoles,
} from 'src/common/enums';
import { AssignmentType } from 'src/features/management-dashboard/assignments/assignment.types';
import { UserTheme } from 'src/common/constants/user';

import { Streak } from '../streak';

export interface UserData {
  user: {
    _id: string;
    username: string;
    avatar: string;
    email: string;
    verified: boolean;
    fullName: string;
    tutorials: UserTutorialsDocument;
    isPremium: boolean;
    isPaused: boolean;
    companies: UserCompany[];
    badgeNames: string[];
    discordToken: string;
    streak: Streak;
    twoFactorAuth: UserTwoFactorAuth;
    /**
     * This contains internal subscriptions of types voucher or company (which includes B2B seat "subscription")
     * **B2C subscriptions are not included here and should be fetched separately.**
     */
    subscriptions: InternalUserSubscription[];
    roles: UserRoles[];
    accountType: UserAccountTypes;
    dateSignUp: string;
    intercomUserHmac: string;
    cardDetails: {
      cardExpiryDate?: string;
      isCardExpiringSoon?: boolean;
    };
    demographics: {
      experience?: string;
      howHeard?: UserHowHeard;
      field?: UserField;
      experienceCyber?: UserExperienceCyber;
      experienceCyberDetail?: UserExperienceCyberDetail;
      institutionType?: string;
      institutionName: string;
      phoneNumber: string;
      occupation: string;
      goal?: UserGoal;
    };
    local: {
      githubUsername: string;
      twitterUsername: string;
      linkedInUsername: string;
      instagramUsername: string;
      personalWebsite: string;
      loginType: string;
      country: string;
      about: string;
      path: string;
      totalPoints: number;
      ctfTeams: [
        {
          _id: string;
        },
      ];
      theme: UserTheme;
      signupSource?: string;
    };
    business: {
      occupation: string;
      team: string;
      jobTitle: string;
      businessEmail: string;
      companyName: string;
    };
    settings: {
      tutor: {
        sessions: {
          [key: string]: {
            sessionId: string;
            shouldSeeIntro?: boolean;
          };
        };
        hasSeenAiTermsOfUseBanner: boolean;
        notificationsEnabled: boolean;
        lastInteractionAt: Date;
      };
    };
    tutorEnabled: boolean;
  };
}

interface UserTwoFactorAuth {
  isTwoFactorEnabled: boolean;
}

export interface UserCompany {
  _id: string;
  name: string;
  isDeadlinePassed: boolean;
}

export * from './subscription';
/**
 * Represents the internal subscription data for a user.
 * **This interface should be used for internal subscription data only.**
 * **To retrieve chargebee B2C subscriptions, use a separate query.**
 */
export interface InternalUserSubscription {
  companyName?: string;
  type: 'premium-annual' | 'premium-monthly' | 'voucher' | 'company';
  status: 'active' | 'paused' | 'cancelled';
  endDate?: string | null;
  renewDate?: string | null;
  pausedDate?: string | null;
  amount?: string;
  currencyCode?: string;
  currencySymbol?: '$' | '£';
  companyId?: string;
  paymentMethod?: string;
  features?: { paths: boolean; sso: boolean; socSim: boolean };
}

export interface UserTutorialsDocument {
  networks: boolean;
  rooms: boolean;
  attackbox: boolean;
  staticsite: boolean;
  pathFirstRoom: boolean;
}

export interface UserAssignmentItem {
  _id: string;
  title: string;
  created: string;
  deadlineDate?: string;
  roomCode: string;
  percentageOfCompletion: number;
  status: StatusEnum;
  assignmentType: AssignmentType;
  url: string;
}

export interface UserAssignments {
  assignments: UserAssignmentItem[];
}

export type DashboardNotification = {
  showEmail: boolean;
  welcomeTask: WelcomeTasksData;
  showWorkspace: boolean;
};

export type WelcomeTasksData = {
  isEnrolledInPath: boolean;
  hasAnyAnsweredQuestions: boolean;
  hasUserCompletedARoom: boolean;
  hasAtLeastTwoDayStreak: boolean;
  hasDismissedWelcomeTasks: boolean;
  hasDismissedFirstFiveBadge: boolean;
};

export enum DismissUserDashboardNotificationItems {
  STREAK_NOTIFICATION = 'streakNotification',
  WORKSPACES_AD = 'workspacesAd',
  HAS_DISMISSED_WELCOME_TASKS = 'hasDismissedWelcomeTasks',
  HAS_DISMISSED_FIRST_FIVE_BADGE = 'hasDismissedFirstFiveBadge',
}
