import React from 'react';
import DOMPurify from 'dompurify';
import { countries } from 'country-flags-svg-v2';
import { addDays, addWeeks } from 'date-fns';

import { ONE_MIN_IN_MS, ONE_DAY_IN_MS } from 'src/common/constants';
import { CalculateDeadlineParams } from 'src/features/paths/components/auth-main-content/components/learner-scheduler/learner-scheduler.types';

import { UserData } from '../interfaces';

export * from './get-rank-title';

export * from './pdfExtension';

export const recoveryCodesToText = (recoveryCodes: string[]) => {
  let text = '';
  for (const [index, recoveryCode] of recoveryCodes.entries()) {
    if (index === 0) {
      text = recoveryCode;
    } else if (index % 2 === 0) {
      text = `${text}\n${recoveryCode}`;
    } else {
      text = `${text} ${recoveryCode}`;
    }
  }
  return text;
};

export const convertDateToUTC = (date: Date | string) => {
  const newDate = new Date(date);
  return new Date(newDate.getTime() + newDate.getTimezoneOffset() * ONE_MIN_IN_MS);
};

export const isDeadlinePassed = (deadline: string) => {
  const nowUTC = convertDateToUTC(new Date());
  const deadlineUTC = convertDateToUTC(deadline);

  return nowUTC >= deadlineUTC;
};

export const getDifferenceInDays = (deadline: string) => {
  const nowUTC = convertDateToUTC(new Date());
  const deadlineUTC = convertDateToUTC(deadline);

  const diffInMs = deadlineUTC.getTime() - nowUTC.getTime();
  return Math.ceil(diffInMs / ONE_DAY_IN_MS);
};

export const extractB2BUserRoles = (user: UserData['user'] | undefined) => ({
  isCompanyAdmin: !!user?.companies?.length,
  isB2BUserActive: !!user?.subscriptions?.find((sub) => sub.type === 'company' && sub.status === 'active'),
});

export const isB2BUserOnly = (user: UserData['user'] | undefined) => {
  if (!user) return false;
  const { isCompanyAdmin, isB2BUserActive } = extractB2BUserRoles(user);
  return isB2BUserActive && !isCompanyAdmin;
};

export const generateRandomToken = () => {
  const randomBytes = new Uint8Array(20);
  crypto.getRandomValues(randomBytes);
  return [...randomBytes].map((byte) => byte.toString(16).padStart(2, '0')).join('');
};

export const generateOrRetrieveAnonymousId = () => {
  let anonymousId = localStorage.getItem('anonymousId');
  if (!anonymousId) {
    anonymousId = generateRandomToken();
    localStorage.setItem('anonymousId', anonymousId);
  }
  return anonymousId;
};

export const findCountryNameByIso2Code = (iso2: string): string => {
  const country = countries.find((someCountry) => someCountry.iso2 === iso2.toUpperCase());
  return country?.name ?? '';
};

export const sanitizeAndRenderHtmlString = (htmlString: string) => {
  const sanitizedHTML = DOMPurify.sanitize(htmlString);
  return React.createElement('div', {
    dangerouslySetInnerHTML: { __html: sanitizedHTML },
  });
};

/**
 * Helper function to check if the string provided as the argument is a valid URL
 */
export const isValidURL = (str: string) => {
  const domainPattern = '(?<domain>[\\w-]+\\.)+[\\w]{2,}';
  const ipPattern = '(?<ip>(\\d{1,3}\\.){3}\\d{1,3})';
  const portPattern = '(:(?<port>\\d+))?';
  const pathPattern = '(?<path>/[\\w-./%+&:]+)*';
  const queryPattern = '(\\?(?<query>[\\w-./?%+&=:]+))?';
  const fragmentPattern = '(#(?<fragment>[\\w-./_%+&]+))?';

  const pattern = new RegExp(
    `^(https?://)?(${domainPattern}|${ipPattern})${portPattern}${pathPattern}${queryPattern}${fragmentPattern}$`,
    'i',
  );

  return pattern.test(str);
};

export const isValidImageUrl = (url: string) => {
  const isImageUrl = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|svg))/i.test(url);
  return isImageUrl;
};

/**
 * Redirects the browser to a specific path on the same domain. Support localhost as well as other envs
 * @param redirectPath path in URL
 * @param toReact include /r/ by default
 * @param keepHistory while preserving history in browser, if kept user can press return and come back to page
 */
export const redirectToPath = (redirectPath: string, keepHistory = false, toReact = true) => {
  const port = (window.location.port && (toReact ? `:${window.location.port}` : ':1337')) || ''; // support localhost
  const hostName = `${window.location.hostname}${port}/`;
  const includeReact = toReact ? 'r/' : '';
  const redirectUrl = `${window.location.protocol}//${hostName}${includeReact}${redirectPath}`;
  if (keepHistory) {
    window.location.assign(redirectUrl);
  } else {
    window.location.replace(redirectUrl);
  }
};

/**
 * Safely decodes text by using regular expression to replace any '%' that is not followed by two valid hex characters
 * then decodeURIComponent
 * @param text - string
 */
export const decode = (text: string) => {
  try {
    return decodeURIComponent(text.replace(/%(?![\dA-Fa-f]{2})/g, '%25'));
  } catch {
    return text;
  }
};

export const calculateEstimatedCompletionDate = ({
  totalPathHours,
  completedHours,
  hoursPerWeek,
}: CalculateDeadlineParams): Date => {
  const remainingHours = totalPathHours - completedHours;

  if (remainingHours <= 0 || hoursPerWeek <= 0) {
    return new Date();
  }

  const weeksNeeded: number = remainingHours / 60 / hoursPerWeek;
  const wholeWeeks = Math.floor(weeksNeeded);
  const fractionalWeeks = weeksNeeded - wholeWeeks;
  const daysNeeded = Math.round(fractionalWeeks * 7);

  let estimatedCompletionDate = addWeeks(new Date(), wholeWeeks);
  estimatedCompletionDate = addDays(estimatedCompletionDate, daysNeeded);

  return estimatedCompletionDate;
};
