import { JsonLdSchema } from './seo.types';

// Twitter creator name
export const CREATOR_NAME = '@realtryhackme';

// JSON-LD schema
export const DEFAULT_JSON_LD_SCHEMA: JsonLdSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: 'https://tryhackme.com',
  name: 'TryHackMe',
  logo: 'https://tryhackme.com/img/favicon.png',
  description: 'TryHackMe is a free online platform for learning cyber security, using hands-on exercises and labs, all through your browser!',
  email: 'support@tryhackme.com',
  sameAs: ['https://www.linkedin.com/company/tryhackme', 'https://twitter.com/realtryhackme']
};
export const PATH_OUTLINE_JSON_LD_BASE_SCHEMA: JsonLdSchema = {
  ...DEFAULT_JSON_LD_SCHEMA,
  '@context': 'https://schema.org',
  '@type': 'Course',
  hasCourseInstance: [{
    '@type': 'CourseInstance',
    courseMode: 'Online'
  }],
  timeRequired: 'P3W',
  isAccessibleForFree: true,
  offers: {
    '@type': 'Offer',
    category: 'Subscription'
  },
  inLanguage: 'en'
};
export const MODULE_JSON_LD_BASE_SCHEMA: JsonLdSchema = {
  ...DEFAULT_JSON_LD_SCHEMA,
  '@context': 'https://schema.org',
  '@type': 'Course',
  hasCourseInstance: [{
    '@type': 'CourseInstance',
    courseMode: 'Online'
  }],
  timeRequired: 'PT5H',
  isAccessibleForFree: true,
  offers: {
    '@type': 'Offer',
    category: 'Subscription'
  },
  inLanguage: 'en'
};
export const DEFAULT_META_TITLE = 'Cyber Security Training Platform';
export const DEFAULT_META_DESC = 'TryHackMe is an online platform for learning and teaching cyber security, all through your browser!';
export const DEFAULT_SEO_IMAGE = 'https://tryhackme.com/img/meta/default.png';