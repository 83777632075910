import styled from 'styled-components';
import { Link } from 'react-router-dom';

export * from './keyframes';
export * from './title';
export * from './rooom-header-styles';
export * from './social-buttons';

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.info};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const StyledH2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familyUbuntu};
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 3.7rem;
  padding-bottom: 0.6rem;
  margin-bottom: 3rem;
  margin-top: 4.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    margin-top: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
  }
`;

export const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: 2.6rem;
  color: ${({ theme }) => theme.colors.text.main};
`;
