import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { lightColors, StyledButton } from '@tryhackme/thm-ui-components';
import { AnnouncementComponentProps } from 'src/common/interfaces/announcements';
import { Countdown } from '../countdown';
import { useAnnouncements } from '../../hooks/use-announcements';
import { useAnnouncementCta } from '../../hooks/use-announcement-cta-link';
import { BlackFridaySaleBarProps } from './black-friday-sale-bar.types';
import { StyledBar, StyledCtaTimer, StyledHeading, StyledHighlight } from './black-friday-sale-bar.styles';
export const BlackFridaySaleBar: FC<AnnouncementComponentProps & BlackFridaySaleBarProps> = ({
  name,
  thin
}) => {
  const theme = useTheme();
  const {
    timeLeft,
    announcement
  } = useAnnouncements(name);
  const [ctaText, urlToNavigate] = useAnnouncementCta();
  return timeLeft ? <ThemeProvider theme={{
    ...theme,
    colors: lightColors
  }}>
      <StyledBar $thin={!!thin} data-testid="announcement-bar">
        <StyledHeading $thin={!!thin}>
          BLACK FRIDAY: <StyledHighlight>{announcement?.amount}% off</StyledHighlight> {!thin && 'TryHackMe'} annual
          subscription!
        </StyledHeading>
        <StyledCtaTimer>
          {!thin && <StyledButton as={Link} to={urlToNavigate} variant="solid" color="primary">
              {ctaText}
            </StyledButton>}
          <Countdown showMessage includeDays timeLeft={timeLeft} />
        </StyledCtaTimer>
      </StyledBar>
    </ThemeProvider> : null;
};