import { FC, useMemo } from 'react';
import { PriceProps } from './price.types';
export const Price: FC<PriceProps> = ({
  price,
  symbol,
  children,
  className
}) => {
  const [int, decimal] = useMemo(() => {
    if (price === undefined) {
      return [null, null];
    }
    return [Math.trunc(price), Number.isInteger(price) ? null : price.toFixed(2).split('.')[1]!];
  }, [price]);
  return int !== null && symbol ? <span className={className}>{children(symbol + int.toString(), decimal ? `.${decimal}` : '')}</span> : null;
};