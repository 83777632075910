import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { darkColors, Modal, StyledButton } from '@tryhackme/thm-ui-components';
import { AnnouncementComponentProps } from 'src/common/interfaces/announcements';
import { usePricing } from 'src/common/hooks';
import { useAnnouncementStorage } from 'src/features/announcements/hooks/use-announcement-storage';
import { Price } from 'src/features/pricing/components/price';
import { Countdown } from '../countdown/countdown';
import { useAnnouncements } from '../../hooks/use-announcements';
import { useAnnouncementCta } from '../../hooks/use-announcement-cta-link';
import { Benefits } from './components/benefits';
import { StyledContent, StyledCenteredContent, StyledHeading, StyledMessage, StyledSubTitle, StyledText, StyledButtons, StyledOldPrice } from './black-friday-sale-modal.styles';
export const BlackFridaySaleModal: FC<AnnouncementComponentProps> = ({
  name
}) => {
  const theme = useTheme();
  const {
    timeLeft,
    announcement
  } = useAnnouncements(name);
  const {
    isSeen,
    markAsSeen
  } = useAnnouncementStorage(announcement);
  const [ctaText, urlToNavigate] = useAnnouncementCta();
  const {
    price: priceStr,
    symbol
  } = usePricing(true);
  const [open, setOpen] = useState(!isSeen);
  const price = Number.parseFloat(priceStr);
  const discountedPrice = price * (100 - (announcement?.amount ?? 0)) / 100;
  useEffect(() => {
    if (isSeen) {
      setOpen(false);
    }
  }, [isSeen]);
  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) return;
    markAsSeen();
    setOpen(false);
  };
  return <ThemeProvider theme={{
    ...theme,
    colors: darkColors
  }} data-sentry-element="ThemeProvider" data-sentry-component="BlackFridaySaleModal" data-sentry-source-file="black-friday-sale-modal.tsx">
      <Modal open={open && !!timeLeft} onOpenChange={onOpenChange} preventCloseOnOutsideClick data-sentry-element="Modal" data-sentry-source-file="black-friday-sale-modal.tsx">
        <StyledContent data-sentry-element="StyledContent" data-sentry-source-file="black-friday-sale-modal.tsx">
          <StyledCenteredContent data-sentry-element="StyledCenteredContent" data-sentry-source-file="black-friday-sale-modal.tsx">
            <StyledHeading data-sentry-element="StyledHeading" data-sentry-source-file="black-friday-sale-modal.tsx">BLACK FRIDAY: {announcement?.amount}% off</StyledHeading>
            <StyledSubTitle data-sentry-element="StyledSubTitle" data-sentry-source-file="black-friday-sale-modal.tsx">TryHackMe annual subscription</StyledSubTitle>
          </StyledCenteredContent>
          <Countdown showMessage includeDays timeLeft={timeLeft} data-sentry-element="Countdown" data-sentry-source-file="black-friday-sale-modal.tsx" />
          <Benefits data-sentry-element="Benefits" data-sentry-source-file="black-friday-sale-modal.tsx" />
          <StyledCenteredContent data-sentry-element="StyledCenteredContent" data-sentry-source-file="black-friday-sale-modal.tsx">
            <StyledMessage data-sentry-element="StyledMessage" data-sentry-source-file="black-friday-sale-modal.tsx">
              Subscribe for{' '}
              <StyledOldPrice price={price} symbol={symbol} data-sentry-element="StyledOldPrice" data-sentry-source-file="black-friday-sale-modal.tsx">
                {(int, fraction) => int + fraction}
              </StyledOldPrice>{' '}
              <Price price={discountedPrice} symbol={symbol} data-sentry-element="Price" data-sentry-source-file="black-friday-sale-modal.tsx">
                {(int, fraction) => int + fraction}
              </Price>
              /year
            </StyledMessage>
            <StyledText data-sentry-element="StyledText" data-sentry-source-file="black-friday-sale-modal.tsx">
              Join <span>100,000s+</span> of professionals who have advanced their security careers.
            </StyledText>
          </StyledCenteredContent>
          <StyledButtons data-sentry-element="StyledButtons" data-sentry-source-file="black-friday-sale-modal.tsx">
            <StyledButton as={Link} role="button" to={urlToNavigate} aria-label="Subscribe now" variant="solid" color="primary" onClick={markAsSeen} data-sentry-element="StyledButton" data-sentry-source-file="black-friday-sale-modal.tsx">
              {ctaText}
            </StyledButton>
          </StyledButtons>
        </StyledContent>
      </Modal>
    </ThemeProvider>;
};