import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetUserQuery } from 'src/common/slices';
import { AnnoucementsProps } from './announcements.types';
import { checkIfShouldShow } from './helpers';
export const Announcements: FC<AnnoucementsProps> = ({
  config,
  children
}) => {
  const {
    name
  } = config;
  const {
    pathname
  } = useLocation();
  const {
    data: userDataResponse
  } = useGetUserQuery();
  const shouldShow = useMemo(() => {
    const {
      pathsToExclude,
      pathsToInclude
    } = config;
    const canShow = config.canShow ? config.canShow(pathname, userDataResponse?.data) : true;
    return canShow && checkIfShouldShow(pathsToExclude || [], pathsToInclude || [], pathname);
  }, [pathname, config, userDataResponse]);
  return shouldShow ? children(name) : null;
};