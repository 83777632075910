import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AlertQueueFilterFormControls } from 'src/features/soc-sim/alert-queue/alert-queue.types';

export const SEEN_ALERT_STORE_KEY = 'last-seen-incident-id';

export const ALERT_QUEUE_FILTER_STORAGE_KEY = 'alert-queue-filter';

export interface SocSimState {
  lastSeenIncidentId: Partial<Record<string, number>>;
  alertQueueFilters: Record<string, AlertQueueFilterFormControls>;
}

const getInitialLastSeenIncidentId = (): SocSimState['lastSeenIncidentId'] => {
  const item = localStorage.getItem(SEEN_ALERT_STORE_KEY);
  if (!item) return {};
  try {
    return JSON.parse(item) as SocSimState['lastSeenIncidentId'];
  } catch {
    return {};
  }
};

const getInitialAlertQueueFilters = (): SocSimState['alertQueueFilters'] => {
  const savedFilters = localStorage.getItem(ALERT_QUEUE_FILTER_STORAGE_KEY);
  if (!savedFilters) return {};
  try {
    return JSON.parse(savedFilters) as SocSimState['alertQueueFilters'];
  } catch {
    return {};
  }
};

const socSimSlice = createSlice({
  name: 'socSim',
  initialState: (): SocSimState => {
    const lastSeenIncidentId = getInitialLastSeenIncidentId();
    const alertQueueFilters = getInitialAlertQueueFilters();

    return { lastSeenIncidentId, alertQueueFilters };
  },
  reducers: {
    setLastSeenIncidentId: (state, action: PayloadAction<{ runId: string; incidentId: number }>) => {
      if (action.payload.incidentId > (state.lastSeenIncidentId[action.payload.runId] ?? 0)) {
        const lastSeenIncidentId = { [action.payload.runId]: action.payload.incidentId };
        localStorage.setItem(SEEN_ALERT_STORE_KEY, JSON.stringify(lastSeenIncidentId));

        return {
          ...state,
          lastSeenIncidentId,
        };
      }

      return state;
    },
    setAlertQueueFilters: (state, action: PayloadAction<{ runId?: string; filters: AlertQueueFilterFormControls }>) => {
      const { runId, filters } = action.payload;
      if (runId) {
        localStorage.setItem(ALERT_QUEUE_FILTER_STORAGE_KEY, JSON.stringify({ [runId]: filters }));
        const alertQueueFilters = { [runId]: filters };

        return {
          ...state,
          alertQueueFilters,
        };
      }

      return state;
    },
  },
});

export const { setLastSeenIncidentId, setAlertQueueFilters } = socSimSlice.actions;

const socSimReducer = socSimSlice.reducer;
export { socSimReducer };
