import { FC, useMemo } from 'react';
import { CountdownProps } from './countdown.types';
import { StyledClockSeparator, StyledCountdown, StyledMessage, StyledTimeblock, StyledTimeblockValue, StyledTimer, StyledUnit } from './countdown.styles';
export const Countdown: FC<CountdownProps> = ({
  showMessage,
  timeLeft,
  includeDays
}) => {
  const [days, hours, mins, secs] = useMemo(() => {
    const d = Math.floor(timeLeft / (3600 * 24));
    const h = Math.floor((timeLeft - d * 3600 * 24) / 3600);
    const m = Math.floor((timeLeft - d * 3600 * 24 - h * 3600) / 60);
    const s = Math.floor(timeLeft - d * 3600 * 24 - h * 3600 - m * 60);
    return [d, h, m, s].map(n => n.toString().padStart(2, '0'));
  }, [timeLeft]);
  return <StyledTimer data-sentry-element="StyledTimer" data-sentry-component="Countdown" data-sentry-source-file="countdown.tsx">
      {showMessage && <StyledMessage>Sale ends in:</StyledMessage>}
      <StyledCountdown data-sentry-element="StyledCountdown" data-sentry-source-file="countdown.tsx">
        {includeDays && <>
            <StyledTimeblock data-testid="countdown-days">
              <StyledTimeblockValue>{days}</StyledTimeblockValue>
              <StyledUnit>days</StyledUnit>
            </StyledTimeblock>
            <StyledClockSeparator>:</StyledClockSeparator>
          </>}
        <StyledTimeblock data-testid="countdown-hours" data-sentry-element="StyledTimeblock" data-sentry-source-file="countdown.tsx">
          <StyledTimeblockValue data-sentry-element="StyledTimeblockValue" data-sentry-source-file="countdown.tsx">{hours}</StyledTimeblockValue>
          <StyledUnit data-sentry-element="StyledUnit" data-sentry-source-file="countdown.tsx">hr</StyledUnit>
        </StyledTimeblock>
        <StyledClockSeparator data-sentry-element="StyledClockSeparator" data-sentry-source-file="countdown.tsx">:</StyledClockSeparator>
        <StyledTimeblock data-testid="countdown-mins" data-sentry-element="StyledTimeblock" data-sentry-source-file="countdown.tsx">
          <StyledTimeblockValue data-sentry-element="StyledTimeblockValue" data-sentry-source-file="countdown.tsx">{mins}</StyledTimeblockValue>
          <StyledUnit data-sentry-element="StyledUnit" data-sentry-source-file="countdown.tsx">min</StyledUnit>
        </StyledTimeblock>
        <StyledClockSeparator data-sentry-element="StyledClockSeparator" data-sentry-source-file="countdown.tsx">:</StyledClockSeparator>
        <StyledTimeblock data-testid="countdown-secs" data-sentry-element="StyledTimeblock" data-sentry-source-file="countdown.tsx">
          <StyledTimeblockValue data-sentry-element="StyledTimeblockValue" data-sentry-source-file="countdown.tsx">{secs}</StyledTimeblockValue>
          <StyledUnit data-sentry-element="StyledUnit" data-sentry-source-file="countdown.tsx">sec</StyledUnit>
        </StyledTimeblock>
      </StyledCountdown>
    </StyledTimer>;
};