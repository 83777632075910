import { useMemo } from 'react';
import { useViewport } from '@tryhackme/thm-ui-components';
import { differenceInDays } from 'date-fns';
import { ExperimentNames } from 'src/common/constants';
import { BREAKPOINTS } from 'src/common/constants/breakpoints';
import { useGetUserQuery } from 'src/common/slices';
import { useGetExperimentGroupsQuery } from 'src/common/slices/experiments';
const PROMOTE_FIRST_FIVE_BADGE_START_DATE = new Date('2024-12-04');
export const EXPERIMENT_DURATION_IN_DAYS = 28;
export const useShouldSeePromoteFirstFiveBadge = () => {
  const {
    data: userData
  } = useGetUserQuery();
  const userSignupDate = userData?.data?.user?.dateSignUp;
  const skipExperimentEndpointCall = !userSignupDate || new Date(userSignupDate) < PROMOTE_FIRST_FIVE_BADGE_START_DATE;
  const {
    data: experimentData
  } = useGetExperimentGroupsQuery([ExperimentNames.PROMOTE_FIRST_FIVE], {
    skip: skipExperimentEndpointCall
  });
  const isExperimentEnabled = experimentData?.data?.promoteFirstFive === 'treatment';
  const isUserEligableToSeeBadgePromotion = userSignupDate ? differenceInDays(new Date(), new Date(userSignupDate)) < EXPERIMENT_DURATION_IN_DAYS : false;
  return {
    isPromoteFirstFiveBadgeEnabled: isExperimentEnabled && isUserEligableToSeeBadgePromotion
  };
};
export const useAddCtaPathOutlineRoomsV2Experiment = () => {
  const {
    width
  } = useViewport();
  const isMobile = width < BREAKPOINTS.MOBILE;
  const {
    data: experimentData,
    isLoading: isExperimentDataLoading
  } = useGetExperimentGroupsQuery<{
    data: {
      data: {
        addCtaPathOutlineRoomsV2: 'control' | 'treatment';
      };
    };
    isLoading: boolean;
  }>([ExperimentNames.ADD_CTA_PATH_OUTLINE_ROOMS_V2], {
    skip: isMobile
  });
  return useMemo(() => ({
    isEnabled: !isMobile && experimentData?.data?.[ExperimentNames.ADD_CTA_PATH_OUTLINE_ROOMS_V2] === 'treatment',
    isLoading: isExperimentDataLoading
  }), [experimentData?.data, isExperimentDataLoading, isMobile]);
};