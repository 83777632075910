export const TOTAL_NUMBER_OF_WELCOME_TASKS = 4;
export const TOTAL_NUMBER_OF_WELCOME_TASKS_PROMOTE_FIRST_FIVE_EXPERIMENT = 3;

export const TEMPORARY_WELCOME_DATA = {
  isEnrolledInPath: true,
  hasAnyAnsweredQuestions: true,
  hasUserCompletedARoom: true,
  hasAtLeastTwoDayStreak: true,
  hasDismissedWelcomeTasks: false,
  hasDismissedFirstFiveBadge: false,
};
