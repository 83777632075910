import { useEffect, useMemo, useState } from 'react';

import { AnnouncementName } from 'src/common/enums/announcements';

import { useGetAnnouncementsQuery } from '../announcements.slice';

export const useAnnouncements = (name: AnnouncementName) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const { data: announcementsResponse } = useGetAnnouncementsQuery();

  const announcement = useMemo(() => {
    if (!announcementsResponse?.data) return null;
    const { data } = announcementsResponse;

    if (data.name !== name) {
      return null;
    }

    return data;
  }, [announcementsResponse, name]);

  useEffect(() => {
    if (!announcement) return;

    // Enable startDate checks at some point
    // const start = new Date(announcement.startDate).getTime();
    const end = new Date(announcement.endDate).getTime();

    const intervalId = setInterval(() => {
      const now = Date.now();
      // const outOfRange = now < start || now > end;
      const outOfRange = now > end;
      setTimeLeft(outOfRange ? 0 : Math.floor((end - now) / 1000));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [announcement]);

  return { timeLeft, announcement };
};
