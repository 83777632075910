import { AnnouncementName } from 'src/common/enums/announcements';
import { AnnouncementConfig } from 'src/common/interfaces/announcements';

import { BlackFridaySaleBar } from './components/black-friday-sale-bar';
import { BlackFridaySaleModal } from './components/black-friday-sale-modal';

export const topAnnoucementBar: AnnouncementConfig = {
  name: AnnouncementName.BLACK_FRIDAY_2024,
  pathsToExclude: [
    '/onboarding/*',
    '/why-subscribe',
    '/manage-account/subscription',
    '/pricing',
    '/welcome',
    '/payment/pending',
    '/feedback/subscription',
    '/room/*',
    '/echo',
    '/path/outline/*',
    '/recapme/*',
  ],
  canShow(_, user) {
    return !!user;
  },
  Component: BlackFridaySaleBar,
};

export const embeddedAnnouncementBar: AnnouncementConfig = {
  name: AnnouncementName.BLACK_FRIDAY_2024,
  pathsToInclude: ['/why-subscribe', '/pricing', '/onboarding/subscribe'],
  Component: BlackFridaySaleBar,
};

export const currentAnnouncementModal: AnnouncementConfig = {
  name: AnnouncementName.BLACK_FRIDAY_2024,
  pathsToInclude: ['/dashboard'],
  Component: BlackFridaySaleModal,
};
