import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabList, TabPanel, RoomType, LearningRoom, StyledButton, ProgressBar, StyledFlex, SwitchThemeContext } from '@tryhackme/thm-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpenCover, faBookmark, faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { StyledLoading } from 'src/common/components';
import { useGetRoomsQuery } from 'src/features/my-rooms/my-rooms.slice';
import { DashboardEvent } from 'src/common/types';
import { useTrackEventMutation } from 'src/common/slices';
import { ThemeColorMode } from 'src/common/enums';
import { StyledTitle } from 'src/common/styles';
import { useGetCurrentModuleQuery } from '../../dashboard.slice';
import { useGetCurrentPathQuery } from '../continue-learning/continue-learning.slice';
import { INTRODUCTION_PATH } from '../continue-learning/continue-learning.constants';
import { ProgressBarWrapper, StyledButtonsWrapper, StyledLearningRoomsWrapper, StyledMyLearning, StyledPathProgress, StyledPathTitle, StyledPlayIcon, StyledResumeLearningButton, StyledRoomTitle, StyledTab, StyledTitleWrapper, StyledViewMoreButton } from './my-learning.styles';
import { EmptyState } from './components';
import { Room } from './my-learning.types';
import { useGetRecentRoomsQuery } from './my-learning.slice';
import { transformApiDataToRoom } from './helpers/transform-data-to-room';
export const MyLearning = () => {
  const [activeTab, setActiveTab] = useState('current');
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState('auto');
  const [trackEvent] = useTrackEventMutation();
  const {
    data: currentRoomsData,
    isFetching: isFetchingCurrent
  } = useGetCurrentModuleQuery();
  const incompleteRoom = currentRoomsData?.data?.find(({
    progress
  }) => progress < 100);
  const incompleteRoomCode = incompleteRoom?.code;
  const redirectPath = incompleteRoomCode ? `/room/${incompleteRoomCode}` : '/hacktivities';
  const {
    data: recentRoomsData,
    isFetching: isFetchingRecent
  } = useGetRecentRoomsQuery(undefined, {
    skip: activeTab !== 'recent'
  });
  const {
    data: currentPathData
  } = useGetCurrentPathQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const {
    theme
  } = useContext(SwitchThemeContext);
  const isDarkMode = theme === ThemeColorMode.DARK;
  const {
    progress: currentPathProgress,
    path: {
      title: currentPathTitle
    }
  } = currentPathData?.data?.path ? currentPathData.data : INTRODUCTION_PATH;
  const {
    data: savedRoomsData,
    isFetching: isFetchingSaved
  } = useGetRoomsQuery({
    page: 1,
    type: 'saved',
    isRoomCompleted: false
  }, {
    skip: activeTab !== 'saved'
  });
  const currentRooms = currentRoomsData?.data;
  const recentRooms = recentRoomsData?.data;
  const savedRooms = (savedRoomsData?.data?.docs ?? []).map(element => transformApiDataToRoom(element));
  const handleTabChange = (value: string) => {
    setActiveTab(value);
    let eventName: DashboardEvent;
    switch (value) {
      case 'current':
        {
          eventName = 'select-current-tab';
          break;
        }
      case 'recent':
        {
          eventName = 'select-recentrooms-tab';
          break;
        }
      case 'saved':
        {
          eventName = 'select-savedrooms-tab';
          break;
        }
      default:
        {
          eventName = 'select-current-tab';
        }
    }

    // eslint-disable-next-line no-void
    void trackEvent({
      event: eventName
    });
  };
  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    if (contentWrapper) {
      const newHeight = `${contentWrapper.scrollHeight}px`;
      setHeight(newHeight);
    }
  }, [activeTab, isFetchingCurrent, isFetchingRecent, isFetchingSaved, currentRooms, recentRooms, savedRooms]);
  const renderContent = useCallback((rooms: Room[], loading: boolean, slice = true) => {
    if (loading) {
      return <>
            <StyledLoading height="8rem" data-testid="room-loading" />
            <StyledLoading height="8rem" data-testid="room-loading" />
            <StyledLoading height="8rem" data-testid="room-loading" />
          </>;
    }
    if (rooms.length === 0) {
      return <EmptyState valueTab={activeTab} />;
    }
    const displayRooms = slice ? rooms.slice(0, 5) : rooms;
    return displayRooms.map(room => <LearningRoom key={room.code} title={room.title} description={room.description} image={room.imageURL ?? ''} progressValue={room.progress} roomType={room.type ?? RoomType.Challenge} code={room.code} activeTab={activeTab} />);
  }, [activeTab]);
  return <StyledMyLearning ref={contentWrapperRef} $maxHeight={height} data-sentry-element="StyledMyLearning" data-sentry-component="MyLearning" data-sentry-source-file="my-learning.tsx">
      <Tabs defaultValue="current" onValueChange={value => handleTabChange(value)} data-sentry-element="Tabs" data-sentry-source-file="my-learning.tsx">
        <StyledTitleWrapper data-sentry-element="StyledTitleWrapper" data-sentry-source-file="my-learning.tsx">
          <StyledTitle data-sentry-element="StyledTitle" data-sentry-source-file="my-learning.tsx">My Learning</StyledTitle>
          <TabList data-sentry-element="TabList" data-sentry-source-file="my-learning.tsx">
            <StyledTab value="current" data-sentry-element="StyledTab" data-sentry-source-file="my-learning.tsx">
              <FontAwesomeIcon icon={faBookOpenCover} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="my-learning.tsx" /> Current
            </StyledTab>
            <StyledTab value="recent" data-sentry-element="StyledTab" data-sentry-source-file="my-learning.tsx">
              <FontAwesomeIcon icon={faClockRotateLeft} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="my-learning.tsx" /> Recent
            </StyledTab>
            <StyledTab value="saved" data-sentry-element="StyledTab" data-sentry-source-file="my-learning.tsx">
              <FontAwesomeIcon icon={faBookmark} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="my-learning.tsx" /> Saved
            </StyledTab>
          </TabList>
        </StyledTitleWrapper>

        <TabPanel value="current" data-sentry-element="TabPanel" data-sentry-source-file="my-learning.tsx">
          <StyledPathProgress gap="1rem" flexWrap="no-wrap" data-sentry-element="StyledPathProgress" data-sentry-source-file="my-learning.tsx">
            <StyledPathTitle $isDarkMode={isDarkMode} data-sentry-element="StyledPathTitle" data-sentry-source-file="my-learning.tsx">{currentPathTitle}</StyledPathTitle>
            <StyledFlex gap="0.8rem" flexWrap="no-wrap" justifyContent="flex-start" data-sentry-element="StyledFlex" data-sentry-source-file="my-learning.tsx">
              <ProgressBarWrapper data-sentry-element="ProgressBarWrapper" data-sentry-source-file="my-learning.tsx">
                <ProgressBar height={10} value={Math.round(currentPathProgress)} radius={0.5} data-sentry-element="ProgressBar" data-sentry-source-file="my-learning.tsx" />
              </ProgressBarWrapper>
              <StyledRoomTitle $isDarkMode={isDarkMode} data-sentry-element="StyledRoomTitle" data-sentry-source-file="my-learning.tsx">{Math.round(currentPathProgress)}%</StyledRoomTitle>
            </StyledFlex>
          </StyledPathProgress>
          <StyledLearningRoomsWrapper data-sentry-element="StyledLearningRoomsWrapper" data-sentry-source-file="my-learning.tsx">
            {renderContent(currentRooms ?? [], isFetchingCurrent, false)}
          </StyledLearningRoomsWrapper>
          <StyledButtonsWrapper gap="1rem" justifyContent="flex-end" data-sentry-element="StyledButtonsWrapper" data-sentry-source-file="my-learning.tsx">
            <StyledButton color="primary" variant="outlined" as={Link} to="/paths" data-sentry-element="StyledButton" data-sentry-source-file="my-learning.tsx">
              View path
            </StyledButton>
            {currentPathProgress < 100 ? <StyledResumeLearningButton variant="solid" color="primary" as={Link} to={redirectPath}>
                <StyledPlayIcon icon={faPlay} />
                Resume learning
              </StyledResumeLearningButton> : null}
          </StyledButtonsWrapper>
        </TabPanel>
        <TabPanel value="recent" data-sentry-element="TabPanel" data-sentry-source-file="my-learning.tsx">
          <StyledLearningRoomsWrapper data-sentry-element="StyledLearningRoomsWrapper" data-sentry-source-file="my-learning.tsx">{renderContent(recentRooms ?? [], isFetchingRecent)}</StyledLearningRoomsWrapper>
        </TabPanel>
        <TabPanel value="saved" data-sentry-element="TabPanel" data-sentry-source-file="my-learning.tsx">
          <StyledLearningRoomsWrapper data-sentry-element="StyledLearningRoomsWrapper" data-sentry-source-file="my-learning.tsx">
            {renderContent(savedRooms, isFetchingSaved)}
            {savedRooms && savedRooms.length >= 1 && <StyledViewMoreButton as={Link} to="/rooms?tab=saved" color="info" variant="text">
                View more
              </StyledViewMoreButton>}
          </StyledLearningRoomsWrapper>
        </TabPanel>
      </Tabs>
    </StyledMyLearning>;
};