import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  background: ${({ theme }) => `radial-gradient(
    500px 180px at 110% 90%,
    rgba(235, 0, 55, 1) 10%,
    ${theme.colors.secondary.main})`};
  width: 100%;
  padding: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 0.5rem;
  border: ${({ theme }) => `1px solid ${theme.colors.secondary.lighter}`};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    padding: 2.4rem 4.6rem;
  }
`;

export const StyledTextIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.success.main};
  font-size: ${({ theme }) => theme.fonts.sizes.default};
`;

export const StyledBenefit = styled.div`
  font-size: ${({ theme }) => theme.fonts.sizes.default};

  &:not(:last-child) {
    margin-bottom: 1.4rem;
  }

  span {
    margin-left: 0.8rem;
  }
`;

export const StyledImage = styled.img`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    display: block;
    height: 12.2rem;
  }
`;
