import { useViewport } from '@tryhackme/thm-ui-components';
import { useLocation } from 'react-router-dom';

import { ExperimentNames } from 'src/common/constants';
import { BREAKPOINTS } from 'src/common/constants/breakpoints';
import { UserData } from 'src/common/interfaces';
import { useGetUserQuery } from 'src/common/slices';
import { useGetExperimentGroupsQuery } from 'src/common/slices/experiments';

import { RoomDetailsData } from './room.types';

const ADD_CTA_PATH_OUTLINE_END_DATE = new Date('2024-10-01');

export const useShouldSeePathOulineCTAExperiment = (
  roomDetails: RoomDetailsData | undefined,
  userData: UserData | undefined,
) => {
  const { width } = useViewport();
  const { data: experimentData } = useGetExperimentGroupsQuery([ExperimentNames.ADD_CTA_PATH_OUTLINE_ROOMS]);
  const userSignupDate = userData?.user?.dateSignUp;
  const isAddCtaToPathOutlineTreatmentGroup =
    experimentData?.data?.addCtaPathOutlineRooms === 'treatment' &&
    userSignupDate &&
    new Date(userSignupDate) <= ADD_CTA_PATH_OUTLINE_END_DATE;
  if (!isAddCtaToPathOutlineTreatmentGroup) return false;
  if (width < BREAKPOINTS.MOBILE) return false;
  if (!roomDetails?.breadcrumbs?.path) return false;
  if (userData?.user?.companies && userData.user.companies.length > 0) return false;

  return true;
};

export const useShouldSeeTutorExperiment = (room?: RoomDetailsData) => {
  const { width, height } = useViewport();

  const isMobile = width < BREAKPOINTS.MOBILE || height < 600;
  const isTutorVisible = room?.tutor?.isVisible;
  const isJoined = room?.joined;

  return isJoined && isTutorVisible && !isMobile;
};

const REMOVE_NABVAR_ROOMS_START_DATE = new Date('2024-11-13');
const REMOVE_NABVAR_ROOMS_END_DATE = new Date('2024-12-05');

export const useShouldNotSeeNavigationExperiment = () => {
  const { width } = useViewport();
  const { data: user } = useGetUserQuery();
  const location = useLocation();

  const isRoomPage = location.pathname.includes('/room/');

  const dateSignUp = user?.data?.user?.dateSignUp;

  const userEligableForExperiment = dateSignUp
    ? new Date(dateSignUp) >= new Date(REMOVE_NABVAR_ROOMS_START_DATE) &&
      new Date(dateSignUp) <= new Date(REMOVE_NABVAR_ROOMS_END_DATE)
    : false;

  const { data: experimentData } = useGetExperimentGroupsQuery([ExperimentNames.REMOVE_NAVBAR_ROOMS], {
    skip: !userEligableForExperiment || width < BREAKPOINTS.MOBILE || !isRoomPage,
  });

  const isExperimentEnabled = experimentData?.data?.removeNavbarRooms === 'treatment';

  return isExperimentEnabled;
};
