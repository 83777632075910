import styled, { css } from 'styled-components';

export const SocialButtonCommonStyles = css`
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.familyUbuntu};
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  text-decoration: none;

  &:hover {
    filter: brightness(1.2);
  }

  &[data-social='twitter'] {
    background-color: ${({ theme }) => theme.colors.twitter.main};
    border-color: ${({ theme }) => theme.colors.twitter.main};

    &:hover {
      background-color: ${({ theme }) => theme.colors.twitter.main};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &[data-social='twitter-x'] {
    background-color: black;
    border-color: black;

    &:hover {
      background-color: black;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &[data-social='facebook'] {
    background-color: ${({ theme }) => theme.colors.facebook.main};
    border-color: ${({ theme }) => theme.colors.facebook.main};

    &:hover {
      background-color: ${({ theme }) => theme.colors.facebook.main};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &[data-social='linkedin'] {
    background-color: ${({ theme }) => theme.colors.linkedIn.main};
    border-color: ${({ theme }) => theme.colors.linkedIn.main};

    &:hover {
      background-color: ${({ theme }) => theme.colors.linkedIn.main};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const StyledSocialButton = styled.a`
  ${SocialButtonCommonStyles};
  border-radius: 0.5rem;
  gap: 0.5rem;
  height: 3.6rem;
  justify-content: center;
  padding: 0.9rem 1.6rem;

  svg {
    width: 1.3rem;
    height: 1.4rem;
    margin: 0;

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;
