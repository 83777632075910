import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { StandardActionModal } from 'src/common/components/standard-action-modal';
import { StyledModalIcon } from 'src/common/components/standard-action-modal/standard-action-modal.styles';
import { OptionsModalProps } from 'src/features/room/room.types';
import { StyledModalBodyText } from 'src/features/room/components/commons';
import { useGetUserQuery } from 'src/common/slices';
export const ExpiredMachineModal = ({
  open,
  onOpenChange,
  onCloseCallback
}: Omit<OptionsModalProps, 'roomCode'> & {
  onCloseCallback?: () => void;
}) => {
  const {
    data: userData
  } = useGetUserQuery();
  const navigate = useNavigate();
  const isPremium = useMemo(() => {
    if (!userData?.data) return false;
    const {
      user
    } = userData.data;
    return user.isPremium && !user.isPaused;
  }, [userData]);
  const iconComponent = useMemo(() => <StyledModalIcon data-icon="error" icon={faCircleExclamation} />, []);
  const modalBodyText = useMemo(() => <div>
        <StyledModalBodyText>Your machine has expired and terminated.</StyledModalBodyText>
        {!isPremium && <>
            <br />
            <br />
            <StyledModalBodyText>
              Subscribe now to prevent this from ever happening again.
              <br />
              Premium users get unlimited machine time!
            </StyledModalBodyText>
          </>}
      </div>, [isPremium]);
  return <StandardActionModal modalTitleText="Expired machine" modalBodyText={modalBodyText} dismissButtonTitle="Close" showIcon showActionButton={!isPremium} iconComponent={iconComponent} open={open} onOpenChange={onOpenChange} actionButtonTitle="Subscribe now" actionButtonColor="primary" handleModalAction={() => navigate('/why-subscribe')} {...onCloseCallback && {
    handleCloseButtonAction: onCloseCallback
  }} data-sentry-element="StandardActionModal" data-sentry-component="ExpiredMachineModal" data-sentry-source-file="expired-machine-modal.tsx" />;
};