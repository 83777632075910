import styled from 'styled-components';

export const StyledBar = styled.div<{ $thin: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  padding: 1.6rem;
  border-color: ${({ theme }) => theme.colors.secondary.lighter};
  border-bottom-style: solid;
  border-bottom-width: ${({ $thin }) => ($thin ? '.1rem' : '0rem')};
  background: ${({ theme }) => `linear-gradient(to right, ${theme.colors.black} 60%, ${theme.colors.error.main})`};
  color: ${({ theme }) => theme.colors.text.invert};

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    flex-direction: row;
    min-height: ${({ $thin }) => ($thin ? '6rem' : '8rem')};
    gap: 2.4rem;
  }
`;

export const StyledHeading = styled.span<{ $thin: boolean }>`
  margin: 0;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  font-weight: ${({ theme, $thin }) => ($thin ? theme.fonts.weight.regular : theme.fonts.weight.bolder)};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: 2.4rem;
  }
`;

export const StyledCtaTimer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    gap: 2.4rem;
    flex-direction: row;
  }
`;

export const StyledHighlight = styled.span`
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: ${({ theme }) => theme.fonts.weight.bolder};
`;
