import styled from 'styled-components';

export const StyledTimer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    gap: 1.6rem;
    flex-direction: row;
  }
`;

export const StyledMessage = styled.span`
  font-size: ${({ theme }) => theme.fonts.sizes.default};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    gap: 2.4rem;
    font-size: 2.4rem;
  }
`;

export const StyledCountdown = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    gap: 0.8rem;
  }
`;

export const StyledClockSeparator = styled.div`
  font-size: 2.4rem;
`;

export const StyledTimeblock = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[100]};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  border-radius: 0.5rem;
  padding: 0.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    height: 3.4rem;
  }
`;

export const StyledTimeblockValue = styled.span`
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  line-height: ${({ theme }) => theme.fonts.sizes.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
    line-height: 2rem;
  }
`;

export const StyledUnit = styled.span`
  font-size: ${({ theme }) => theme.fonts.sizes.tiny};
  margin-left: 0.3rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: ${({ theme }) => theme.fonts.sizes.default};
  }
`;
